<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="6"
        >
          <b-form-group>
            <label>From Date</label>
            <b-input
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group>
            <label>To Date</label>
            <b-input
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group>
            <label for="company">Filter Company</label>
            <b-form-select
              id="company"
              v-model="filter.company_id"
              :options="filterCompany"
              type="text"
              @change="onFilterChangeCompany"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group>
            <label for="organization">Filter Organization</label>
            <b-form-select
              id="organization"
              v-model="filter.organization_id"
              :options="filterOrganization"
              type="text"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSetting.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="6"
        >
          <div>
            <JsonExcel
              class="btn btn-success"
              :data="json_data"
              :fields="json_fields"
              worksheet="Total Hours and Minutes Per Department"
              name="totalhoursandminutes.xls"
            >
              Download Excel
            </JsonExcel>
          </div>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSetting.perPage"
        :current-page="tableSetting.currentPage"
        :items="myProvider"
        :fields="tableSetting.fields"
        :sort-by.sync="tableSetting.sortBy"
        :sort-desc.sync="tableSetting.sortDesc"
        :sort-direction="tableSetting.sortDirection"
        :filter="tableSetting.filter"
        :filter-included-fields="tableSetting.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSetting.currentPage * tableSetting.perPage - tableSetting.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSetting.perPage"
              :options="tableSetting.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSetting.currentPage"
            :total-rows="tableSetting.totalRows"
            :per-page="tableSetting.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AdminReportService, SharedListService } from '@/services'
import JsonExcel from 'vue-json-excel'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminReportByItens',

  middleware: ['auth', 'admin'],

  components: {
    JsonExcel
  },

  mixins: [formatter],

  metaInfo () {
    return {
      title: 'By Items'
    }
  },

  data () {
    return {
      editMode: false,
      isBusy: false,
      total_issued_voucher: '',
      json_fields: {
        'Item Name': 'item_name',
        'Total Hours': 'total_hours',
        'Total Minutes': 'total_minutes',
        'Number of Item Booked': 'total_count'
      },
      json_data: [],
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now()),
        company_id: 'ALL',
        organization_id: 'ALL'
      },
      list: {
        companies: []
      },
      organizations: [],
      tableSetting: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'item_name', sortable: true },
          { key: 'total_hours', sortable: true },
          { key: 'total_minutes', sortable: true },
          { key: 'total_count', sortable: true, label: 'Number of Item Booked' }
        ]
      }
    }
  },

  computed: {
    filterCompany () {
      return [{ value: 'ALL', text: 'ALL' }].concat(this.list.companies)
    },
    filterOrganization () {
      return [{ value: 'ALL', text: 'ALL' }].concat(this.organizations)
    }

  },

  mounted () {
    core.index()
    this.getCompanyList()
  },

  methods: {
    async getCompanyList () {
      SharedListService.getCompanyList().then(({ data }) => {
        this.list.companies = data.map(({ id, company_name }) => (
          { text: company_name, value: id }
        ))
      })
    },
    async getCompanyOrganizations () {
      this.organizations = []
      SharedListService.getOrganizationList(this.filter.company_id).then(({ data }) => {
        this.organizations = data.map(({ id, organization_name }) => (
          { text: organization_name, value: id }
        ))
      })
    },
    async myProvider (ctx) {
      try {
        const { data } = await AdminReportService.getItemReport1(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&date_from=${this.filter.date_from}&date_to=${this.filter.date_to}&company_id=${this.filter.company_id}&organization_id=${this.filter.organization_id}`)
        this.tableSetting.totalRows = data.total_rows
        this.json_data = data.items
        return data.items
      } catch {
        return []
      }
    },
    onFilterChange () {
      this.$refs.table.refresh()
    },
    onFilterChangeCompany () {
      this.getCompanyOrganizations()
      this.$refs.table.refresh()
    }
  }
}
</script>
